<template>
  <div>
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple scroll-table"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th
            colspan="1"
            class="style-table-thread"
          >
            TT
          </b-th>
          <b-th colspan="1">
            CÁC CHỈ TIÊU TRONG KỲ BÁO CÁO
          </b-th>
          <b-th colspan="1">
            ĐVT
          </b-th>
          <b-th colspan="1">
            SỐ LIỆU
          </b-th>
          <b-th colspan="1"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in dataList"
          :key="index"
        >
          <b-td
            class="pb-1 pt-1"
            :class="item.isBold ? 'bold-text' : '' || item.isItalic ? 'italic-text' : ''"
          >
            {{ item.stringOrder }}
          </b-td>
          <b-td
            class="pb-1 pt-1"
            :class="item.isBold ? 'bold-text' : '' || item.isItalic ? 'italic-text' : ''"
          >
            {{ item.name }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.unit }}
          </b-td>

          <b-td
            v-if="item.isDataString"
            class="pb-1 pt-1"
          >
            <b-form-input
              :id="`focuss-input-${item.titleReportId}`"
              :value="item.dataString"
              @focus="focusTextChange(item.titleReportId)"
              @change="handleCountString($event, item.titleReportId, item.data1, item.data2)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            <b-form-input
              v-if="item.isEdit && !item.isDataString"
              :id="`focus-inputed-${item.titleReportId}`"
              :value="item.data1"
              @focus="focusValueChange(item.titleReportId)"
              @change="handleCountNumberData1($event, item.titleReportId, item.data2, item.dataString)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            <b-form-input
              v-if="item.isEdit && item.isMultiData && !item.isDataString"
              :id="`focus-input-${item.titleReportId}`"
              :value="item.data2"
              @focus="focusValue2Change(item.titleReportId)"
              @change="handleCountNumberData2($event, item.titleReportId, item.data1, item.dataString)"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BThead,
  BFormInput,
} from 'bootstrap-vue'

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    BFormInput,
  },

  props: {
    submitReportId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dataList: [],
      data1: 0,
      data2: 0,
      dataString: '',
    }
  },
  created() {
    this.fetchData(this.submitReportId)
  },
  methods: {
    // Fetch data
    async fetchData(submitReportId) {
      const param = {
        submitReportId,
      }
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SUBMIT_REPORT_TITLES, {
        params: param,
      })
      this.dataList = data
      this.$hideLoading()
    },

    // Gọi API khi điền vào input
    async handleCountNumberData1(value, id, data2, dataString) {
      const typeVal = Number(value)
      this.data1 = typeVal
      if (!typeVal === true) {
        this.data1 = ''
        const el = document.getElementById(`focus-inputed-${id}`)
        el.style.border = '1px solid red'
        this.$root.$bvToast.toast('Bắt buộc điền số vào ô trống', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        const el = document.getElementById(`focus-inputed-${id}`)
        el.style.border = '1px solid #A6A8B0'
        const model = {
          submitReportId: this.submitReportId,
          titleReportId: id,
          data1: this.data1,
          data2: Number(data2),
          dataString,
        }
        await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT_TITLES, model)
      }
    },

    // Gọi API khi điền vào input
    async handleCountNumberData2(value, id, data1, dataString) {
      const typeVal = Number(value)
      this.data2 = value
      if (!typeVal === true) {
        this.data2 = ''
        const el = document.getElementById(`focus-input-${id}`)
        el.style.border = '1px solid red'
        this.$root.$bvToast.toast('Bắt buộc điền số vào ô trống', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        const el = document.getElementById(`focus-input-${id}`)
        el.style.border = '1px solid #A6A8B0'
        const model = {
          submitReportId: this.submitReportId,
          titleReportId: id,
          data2: Number(this.data2),
          data1: Number(data1),
          dataString,
        }
        await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT_TITLES, model)
      }
    },

    // Gọi API khi điền vào input
    async handleCountString(value, id, data1, data2) {
      this.dataString = value
      const model = {
        submitReportId: this.submitReportId,
        titleReportId: id,
        dataString: this.dataString,
        data1,
        data2,
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT_TITLES, model)
    },
    focusTextChange(index) {
      const el = document.getElementById(`focuss-input-${index}`)
      el.focus()
      el.setSelectionRange(0, el.value.length)
    },
    focusValueChange(index) {
      const el = document.getElementById(`focus-inputed-${index}`)
      el.focus()
      el.setSelectionRange(0, el.value.length)
    },
    focusValue2Change(index) {
      const el = document.getElementById(`focus-input-${index}`)
      el.focus()
      el.setSelectionRange(0, el.value.length)
    },
  },
}
</script>

<style lang="scss">
.scroll-table {
  overflow-y: scroll;
  height: 500px;
}
.table-simple {
  .table {
    thead {
      tr {
        th {
          background-color: rgba(34, 127, 244, 0.12) !important;
        }
      }
    }
  }
  .style-table-thread {
    width: 50px;
  }
  .bold-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2E3A4A !important;
  }
  .italic-text {
    font-family: 'Montserrat';
    font-style: italic;
    font-size: 14px;
    color: #2E3A4A !important;
  }
}
</style>
